import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    <div className="relative rounded-lg -skew-x-6 -translate-y-6 hover:-translate-y-1 hover:-translate-x-0 hover:skew-x-0 p-2 hover:p-3 bg-sky-300 card-compact hover:bg-base-200 transition-all duration-200 [box-shadow:12px_12px] hover:[box-shadow:4px_4px]">
      <Link to={`/single?slug=${data.slug}`}>
        <figure className="w-full h-full">
          <img
            src={data.logo}
            alt={data.title}
            className="w-full h-64 object-fill hover:scale-105"
          />
        </figure>
        <div className="absolute text-neutral-50 bottom-4 left-0 px-4">
          <span className="font-bold">{data.title}</span>
        </div>
      </Link>
    </div>
  );
}
