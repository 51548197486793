import React from "react";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="text-white text-center text-md bg-footer-image">
      <p className="mt-10">
        &copy; {currentYear}
        <span className="ms-2">horizonlearning.xyz</span>
      </p>
    </footer>
  );
}
