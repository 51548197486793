import React from "react";

export default function Loader() {
  return (
    <section className="flex items-center justify-center p-8">
      <div className="flex justify-center items-center">
        <div className="animate-spin rounded-full h-12 w-32 border-t-8 border-b-8 border-yellow-600"></div>
      </div>
    </section>
  );
}
