import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 text-white rounded-lg shadow-2xl m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>horizonlearning.xyz!</b>
        </p>
        <p className="my-3">
          At horizonlearning.xyz, we are committed to providing innovative and
          accessible educational resources to learners around the world. Our
          mission is to empower individuals with the knowledge and skills they
          need to achieve their personal and professional goals.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Founded in 2024, horizonlearning.xyz was created to bridge the gap
          between traditional education and the evolving needs of modern
          learners. We believe in leveraging technology to deliver high-quality
          education that is both flexible and effective.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team is composed of experienced educators, technologists, and
          industry experts who are passionate about education. We work together
          to create engaging and impactful learning experiences for our users.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Online Courses:</b> A diverse range of courses designed to meet
            the needs of learners at all levels.
          </li>
          <li className="my-2">
            <b>Interactive Learning Tools:</b> Innovative tools to enhance the
            learning experience.
          </li>
          <li className="my-2">
            <b>Community Support:</b> A supportive community of learners and
            educators to foster collaboration and growth.
          </li>
        </ul>

        <p className="my-2">
          Thank you for choosing horizonlearning.xyz as your educational
          partner. We are excited to support your learning journey.
        </p>
      </section>
    </>
  );
}
